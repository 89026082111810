
.navbar{ 
    display:flex;
    justify-content: space-between;
    top:0px;
    
    
    z-index:10;
    background-color: var(--secondary-color);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    padding-right:10px;
    
}
#nav-popup-menu-btn{ 
    border:none;
   font-size: 1.5rem;
   background-color: var(--secondary-color);

    

}
#logout-btn{ 
    padding:5px;
   border: none;
    font-size: 1.2rem;
    background-color: var(--secondary-color);
}
#logout-btn:hover{ 
    color:lightgrey;
    cursor:pointer;
   
}

#nav-popup-menu-btn:hover{ 
    color:lightgrey;
    cursor:pointer;
}



