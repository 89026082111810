
.main-container { 
 height:100vh;
  display: flex;
  flex-direction: column;
  
  

}


.holy-grail {
  height:100vh;
   margin-top:5px;
    display: flex;
   
    
   
  }
  .sidebar{ 
  padding-right:10px;
  top:0px;
  height:100%;
  


    
  }
  .middle {
   
    padding-left:10px;
    padding-right: 10px;
    width:100%;
    height: 100%;
  
  }
 
  .side-width{ 
    max-width: 250px;
    min-width: 220px;
  
  }

  .sidebar-width{ 
    max-width:180px
  }

  .left{ 

  }

  .right{ 

  }

  .overflow-tile{ 
    overflow:hidden; 
    width:100%;
  }
  


  @media (max-width: 1343px) {
    .side-width{ 
      display:none;
    }
    
    
  }

  @media (max-width: 2000px) {
   
    .overflow-tile{ 
      overflow:hidden; 
      width:60vw;
    }
    
  
  }

