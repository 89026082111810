.middle-section-top{ 
   display:flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

#previous-household{ 
    margin-right:10px;
}

.household-control-btns{ 
    margin-top:5px;
}

::-webkit-scrollbar {
    width: 7px; 
}

::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 6px; 
}

::-webkit-scrollbar-thumb:hover {
    background-color: white; 

}
.font-size-large{ 
    font-size: 1.5rem;
}
.min-height-400px{ 
    min-height: 400px;
}