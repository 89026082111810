.positon-bottom-right{ 
 
}

.chat-bubble-receive{ 
    background:  #1F1F1F;
    border-radius: 15px;
    padding:10px;
    font-weight: 500;
    margin-bottom:10px;
    width: 125px;
    

}
.margin-right-5px{ 
    padding:6px;
    background-color: none;
}
.send-chat{ 
   margin-left: 50%;
}

.chat-bubble-send{
    background:  lightgreen;
    font-weight: 500;
    color:black;
    
    border-radius: 15px;
    padding:10px;
    
    margin-bottom:10px;
    width:-125px;
}

.chat { 
    height:300px;
    
    overflow-y:scroll;
}

.width-chat{ 
    width:400px;
    z-index:1000;
    
}
.input-width{ 
    
    width: 70%;
}
.control{ 
    padding:10px;
    display:flex;
    justify-content: space-between;
}

.wrap-text{ 
    overflow-wrap: break-word;
}