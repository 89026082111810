.backdrop { 
    width: 100%; 
    height:100%; 
    position:absolute;
    z-index: 10;
    backdrop-filter:blur(10px);
    background: rgba(0, 0, 0, 0.5)
   
   

}


.center-popup {
    position: absolute;
    left: 50%;
    top: 50%;
    width:50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 11;
  }