#avatar-img{ 
    width:auto;
    height:50px;
}

.min-width-185px{ 
min-width: 185px;
}

.slide-left{ 
transition: transform 0.5s ease-in-out;
transform: translateX(-85%);

}

.slide-right{ 
transition: transform 0.5s ease-in-out;
transform: translateX(0);
}
.background-image-avatar{ 
    background-image: URL('./images/avatar.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.opacity-20-percent{ 
    opacity:80%;
}