#household-driver-crud-ops{ 
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 
    

}

#add-driver-crud-ops{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
     
    
}


.black-background{ 
    background-color: black;

}

.text-align-right{ 
    text-align: right;
}
.popup-exit-button{ 
    font-size: 17px;
    border:none;
    background-color: black;
    cursor:pointer;
  }

.border-radius-top-10px{
    border:1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding:5px;
  
}

.button-hover:hover{ 
    background-color: #1F1F1F;
}

.blurred:not(.popup){ 
    backdrop-filter: blur(5px);
}

#add-household-crud-ops{
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#add-uw-action{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#document_tab_table_body  td{
    border:none;
}


.popup{   

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}