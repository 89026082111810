.flex{ 
    display:flex;
}

.flex-direction-column{ 
    flex-direction:column;
}

.bg-black{
    background-color: var(--third-color);
}

li { 
    list-style-type: none;
    
}

.vh-height-full{ 
height: 95vh;
}

.padding-10px-around{ 
    padding:10px;
}

.border-under{ 
    border-bottom: 3px solid white;
}

.margin-bottom-10px{ 
    margin-bottom: 10px;
}
.bg-primary{ 
    background-color: rgb(31,31,31);
}

.space-between{ 
    justify-content: space-between;
}

.width-100-percent{ 
    width:100%;
}

.width-50-percent{ 
    width:50%;
}

.padding-20px-around{ 
    padding:20px;
}

.text-align-center{ 
    text-align: center;
}

.shadow {
    box-shadow: 0 0 10px var(--third-color);
  }

  .button-1{ 
    border:none;
    background-color: rgb(57, 126, 57);
    border-radius: 5px;
    padding:5px;
  }
  
  .margin-top-10px{ 
    margin-top: 10px;
  }

  .cursor-pointer{ 
    cursor: pointer;
  }
  .width-200px{ 
    width:200px;
  }

  .width-full{
    width:100%;
  }

  .width-500px{ 
    width:500px;
  }

  .height-300px{
    height:300px;
  }

  .border-color-white{ 
    border: 2px solid white;
   
  }

