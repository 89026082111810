#dialpad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    max-width: 100%;
    margin: 0 auto;
  }
  
.dialpad-btn   {
    padding: 10px;
    font-size: 1.5em;
    text-align: center;
    background-color: var(--secondary-color);
    border: none;
  }
  
  .dialpad-btn-0{
    grid-column: 1/ 4;
  }

  .stretch-right-side{ 
    height: 34vh;
    flex-grow:2
  }


.max-width-800px{ 
  max-width: 150vh;
}
.stretch-3-right-side{ 
  height: 28%;
  flex-grow:1
}

.max-width-100{ 
 max-width: 100%;
}
