body {
  min-width:100vw;
  padding:0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: .82rem;
  
}

#root{ 
  min-height: 100vh;
  min-width:100vw;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #app, #app {
  min-width:100vw;
  overflow-x:hidden;

  

  
}

:root { 
  --main-color:#121212;
  --secondary-color:#1F1F1F;
  --font-color:white;
  --third-color: black;
  --contrast-color:white;
  --main-accent: lightgreen;
  --second-accent:lightcoral;
  --button-color:#3d3d3d;
  --button-color-hover:#5f5e5e;
}


/* 
Default values
--main-color:#121212;
--secondary-color:#1F1F1F;
--font-color:white;
--third-color: black;
--contrast-color:white;
--main-accent: lightgreen;
--second-accent:lightcoral;
--button-color:#3d3d3d;
--button-color-hover:#5f5e5e;
*/


/*
--main-color: rgb(42, 42, 129);
--secondary-color: #3c3ac0;
--font-color:white;
--third-color: black;
--contrast-color:white;
--main-accent: white;
--second-accent:lightcoral;
--button-color:black; 
--button-color-hover:orange;
 */