.light-dark-container{ 
    background-color: var(--secondary-color) ;
    border-radius: 10px;
    padding:5px;

    
}
.margin-top-50{ 
    margin-top:20px;
}



.background-container{
    background-color: var(--secondary-color) ;
}

.border-box{ 
    border: 2px solid white;
}

.stretch{ 
   
   height:43%;
}

.bottom-padding{ 
    padding-bottom:5px;
}

#left_panel_search_button{
    font-size: 12px;
    margin-left:5px;
}

.background-light-container{ 
    background-color: var(--secondary-color);
}

.hover{ 

}

.hover:hover{ 
    background-color: grey;
}

